import Vue from "vue";
import store from "../store";
import VueRouter, { RouteConfig } from "vue-router";

import SplashScreen from "../views/Splash-Screen.vue";
import MainContent from "../views/MainContent.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: SplashScreen,
  },
  {
    path: "/exit",
    component: () => import("../views/Logout.vue"),
  },
  {
    path: "/asset-tracker",
    component: MainContent,
    beforeEnter: (to, from, next) => {
      if (!store.state.UserAuthenticated) {
        next("/");
      }
      next();
    },
    children: [
      {
        path: "/asset-tracker",
        component: () => import("../views/Dashboard.vue"),
      },

      {
        path: "/asset-tracker/assets",
        component: () => import("../views/Assets.vue"),
      },
      {
        path: "/asset-tracker/assets/history",
        component: () => import("../views/Assets-History.vue"),
      },
      {
        path: "/asset-tracker/assets/:id",
        component: () => import("../views/Asset-Detail.vue"),
      },
      {
        path: "/asset-tracker/new-asset",
        component: () => import("../views/New-Asset.vue"),
      },
      {
        path: "/asset-tracker/edit-asset/:id",
        component: () => import("../views/New-Asset.vue"),
      },

      {
        path: "/asset-tracker/maintenance",
        component: () => import("../views/Maintenance.vue"),
      },
      {
        path: "/asset-tracker/maintenance/:id",
        component: () => import("../views/Maintenance-Detail.vue"),
      },
      {
        path: "/asset-tracker/new-maintenance",
        component: () => import("../views/New-Maintenance.vue"),
      },
      {
        path: "/asset-tracker/edit-maintenance/:id",
        component: () => import("../views/New-Maintenance.vue"),
      },

      {
        path: "/asset-tracker/lease",
        component: () => import("../views/Lease.vue"),
      },
      {
        path: "/asset-tracker/lease/:id",
        component: () => import("../views/Lease-Detail.vue"),
      },
      {
        path: "/asset-tracker/new-lease",
        component: () => import("../views/New-Lease.vue"),
      },
      {
        path: "/asset-tracker/edit-lease/:id",
        component: () => import("../views/New-Lease.vue"),
      },

      {
        path: "/asset-tracker/telematics",
        component: () => import("../views/Telematics.vue"),
      },
      {
        path: "/asset-tracker/telematics/device-event-detail/:id",
        component: () => import("../views/Telematics-event-detail.vue"),
      },
      {
        path: "/asset-tracker/telematics/tag-in-out-detail/:id",
        component: () => import("../views/Tag-In-Out-Detail.vue"),
      },

      {
        path: "/asset-tracker/operator",
        component: () => import("../views/Operator.vue"),
      },
      {
        path: "/asset-tracker/new-operator",
        component: () => import("../views/New-Operator.vue"),
      },
      {
        path: "/asset-tracker/operator-detail/:id",
        component: () => import("../views/Operator-Detail.vue"),
      },
      {
        path: "/asset-tracker/edit-operator/:id",
        component: () => import("../views/New-Operator.vue"),
      },

      {
        path: "/asset-tracker/settings",
        component: () => import("../views/Settings.vue"),
      },
    ],
  },
  {
    path: "*",
    component: () => import("../views/ErrorPage.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
