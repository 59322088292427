























































import { Component, Vue } from "vue-property-decorator";
import {
  BarChart2Icon,
  HomeIcon,
  BriefcaseIcon,
  FlagIcon,
  ZapIcon,
  XIcon,
  ToolIcon,
  SettingsIcon,
} from "vue-feather-icons";

@Component({
  components: {
    BarChart2Icon,
    HomeIcon,
    BriefcaseIcon,
    FlagIcon,
    ZapIcon,
    XIcon,
    ToolIcon,
    SettingsIcon,
  },
})
export default class MobileMenu extends Vue {
  showMenu = false;
  onToggleMenu() {
    this.showMenu = !this.showMenu;
  }
}
