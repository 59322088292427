















import { Component, Vue } from "vue-property-decorator";

@Component
export default class ThemeSwitcher extends Vue {
  themeChecked = false;

  public constructor() {
    super();
    const el = document.documentElement;
    if (this.$store.state.themeAT == "dark") {
      el.classList.add("dark");
      this.themeChecked = true;
    } else {
      el.classList.remove("dark");
    }
  }
  public toggleTheme(): void {
    const el = document.documentElement;

    if (this.$store.state.themeAT == "dark") {
      el.classList.remove("dark");
      this.$store.commit("setLightTheme");
      this.themeChecked = false;
    } else {
      el.classList.add("dark");
      this.$store.commit("setDarkTheme");
      this.themeChecked = true;
    }
  }
  keyup() {
    this.toggleTheme();
  }
}
