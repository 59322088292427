







import { Component, Vue } from "vue-property-decorator";

@Component
export default class App extends Vue {
  public constructor() {
    super();
    const el = document.documentElement;
    if (this.$store.state.themeAT == "dark") {
      el.classList.add("dark");
    } else {
      el.classList.remove("dark");
    }
  }
}
