












































































import { Component, Vue, Watch } from "vue-property-decorator";
import {
  HomeIcon,
  BriefcaseIcon,
  FlagIcon,
  ZapIcon,
  ToolIcon,
  SettingsIcon,
  CalendarIcon,
} from "vue-feather-icons";

@Component({
  components: {
    HomeIcon,
    BriefcaseIcon,
    FlagIcon,
    ZapIcon,
    ToolIcon,
    CalendarIcon,
    SettingsIcon,
  },
})
export default class TopNav extends Vue {
  selectedTopNav = 1;

  mounted() {
    this.onChnageRoute({ path: this.$router.currentRoute.path });
  }

  @Watch("$route")
  onChnageRoute(to: any) {
    let path = to.path.substring(14);
    if (path.indexOf("/", 1) != -1) {
      path = path.substring(0, path.indexOf("/", 1));
    }

    switch (path) {
      case "":
        this.selectedTopNav = 1;
        break;
      case "/assets":
        this.selectedTopNav = 2;
        break;
      case "/new-asset":
        this.selectedTopNav = 2;
        break;
      case "/edit-asset":
        this.selectedTopNav = 2;
        break;
      case "/maintenance":
        this.selectedTopNav = 3;
        break;
      case "/new-maintenance":
        this.selectedTopNav = 3;
        break;
      case "/lease":
        this.selectedTopNav = 4;
        break;
      case "/new-lease":
        this.selectedTopNav = 4;
        break;
      case "/edit-lease":
        this.selectedTopNav = 4;
        break;
      case "/telematics":
        this.selectedTopNav = 5;
        break;
      case "/operator":
        this.selectedTopNav = 6;
        break;
      case "/new-operator":
        this.selectedTopNav = 6;
        break;
      case "/edit-operator":
        this.selectedTopNav = 6;
        break;
      case "/operator-detail":
        this.selectedTopNav = 6;
        break;
      case "/settings":
        this.selectedTopNav = 7;
        break;
      default:
        this.selectedTopNav = 0;
        break;
    }
  }
}
