import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    PROTOCOL: "https",
    ROOT_URL: "durbinservices.com",

    // Auth state
    UserAuthenticated: false,

    // Theme
    themeAT: "light",

    // Toast Message
    toastMessage: {
      boldText: "",
      text: "",
      type: "",
      hidden: true,
    },

    // Profile Data
    currentName: "",
    currentUsername: "",
    currentProfilePicture: "",

    isUserIsManager: false,
  },
  mutations: {
    // Auth
    setUserAuth(state) {
      state.UserAuthenticated = true;
    },
    // Theme
    setLightTheme(state) {
      state.themeAT = "light";
    },
    setDarkTheme(state) {
      state.themeAT = "dark";
    },

    // Toast Message
    showToastMessage(state, { boldText, text, type }) {
      state.toastMessage.boldText = boldText;
      state.toastMessage.text = text;
      state.toastMessage.type = type;
      state.toastMessage.hidden = false;
    },
    hideToastMessage(state) {
      state.toastMessage.boldText = "";
      state.toastMessage.text = "";
      state.toastMessage.type = "";
      state.toastMessage.hidden = true;
    },

    // Profile
    setProfileData(state, { name, username, pic }) {
      state.currentName = name;
      state.currentUsername = username;
      state.currentProfilePicture = pic;
    },

    setIsUserIsManager(state) {
      state.isUserIsManager = true;
    },
  },
  actions: {},
  modules: {},
});
