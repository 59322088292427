










































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import ThemeSwitcher from "../ui/Theme-Switcher.vue";

import {
  ChevronRightIcon,
  BellIcon,
  UserIcon,
  HelpCircleIcon,
  LogOutIcon,
} from "vue-feather-icons";

@Component({
  components: {
    ChevronRightIcon,
    BellIcon,
    UserIcon,
    HelpCircleIcon,
    LogOutIcon,
    ThemeSwitcher,
  },
})
export default class TopBar extends Vue {
  showNotification = false;
  showAccountMenu = false;

  mounted() {
    const el = document.documentElement;
    el.onclick = () => {
      this.showAccountMenu = false;
      this.showNotification = false;
    };

    this.onChnageRoute({ path: this.$router.currentRoute.path });

    this.onGetNotification();
  }

  breadcrumbTitle = "Dashboard";

  @Watch("$route")
  onChnageRoute(to: any) {
    let path = to.path.substring(14);
    if (path.indexOf("/", 1) != -1) {
      path = path.substring(0, path.indexOf("/", 1));
    }

    switch (path) {
      case "":
        this.breadcrumbTitle = "Dashboard";
        break;
      case "/assets":
        this.breadcrumbTitle = "Assets";
        break;
      case "/new-asset":
        this.breadcrumbTitle = "New Asset";
        break;
      case "/edit-asset":
        this.breadcrumbTitle = "Edit Asset";
        break;
      case "/maintenance":
        this.breadcrumbTitle = "Maintenance";
        break;
      case "/new-maintenance":
        this.breadcrumbTitle = "New Maintenance";
        break;
      case "/lease":
        this.breadcrumbTitle = "Lease";
        break;
      case "/new-lease":
        this.breadcrumbTitle = "Lease";
        break;
      case "/edit-lease":
        this.breadcrumbTitle = "Lease";
        break;
      case "/telematics":
        this.breadcrumbTitle = "Telematics";
        break;
      case "/operator":
        this.breadcrumbTitle = "Operator";
        break;
      case "/new-operator":
        this.breadcrumbTitle = "Operator";
        break;
      case "/edit-operator":
        this.breadcrumbTitle = "Operator";
        break;
      case "/operator-detail":
        this.breadcrumbTitle = "Operator";
        break;
      case "/settings":
        this.breadcrumbTitle = "Settings";
        break;
      default:
        this.breadcrumbTitle = "";
        break;
    }

    this.onGetNotification();
  }

  onToggleNotification() {
    setTimeout(() => {
      this.showNotification = true;
    }, 10);
  }
  onToggleAccountMenu() {
    setTimeout(() => {
      this.showAccountMenu = true;
    }, 10);
  }

  async onAppLogout() {
    try {
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://${this.$store.state.ROOT_URL}/app-logout`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.status == 200) {
        this.$store.commit("showToastMessage", {
          boldText: "Successfully!",
          text: "Logged out!",
          type: "Danger",
        });
        setTimeout(() => {
          this.$router.push({ path: "/exit" });
        }, 400);
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    }
  }

  notificationList: any = [];
  async onGetNotification() {
    try {
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/notification`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.status == 200) {
        const data = await response.json();
        this.notificationList = [...data];
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    }
  }
}
