

























































import { Component, Prop, Vue } from "vue-property-decorator";
import { AlertTriangleIcon } from "vue-feather-icons";

@Component({
  components: {
    AlertTriangleIcon,
  },
})
export default class AuthError extends Vue {
  @Prop() errorType!: "Auth" | "Exception";

  title = "Boost your Assets with Durbin Asset Tracker";
  message =
    "A few more clicks to sign in to your asset tracker. Manage all your asset tracking and maintenance in one place";

  mounted() {
    if (this.errorType == "Exception") {
      this.title = "Something went wrong";
      this.message =
        "Brace yourself till we fix this issue, or try again later. Manage all your asset tracking and maintenance in one place";
    }
  }
}
