






























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ThumbsUpIcon, AlertTriangleIcon, XIcon } from "vue-feather-icons";

@Component({
  components: {
    ThumbsUpIcon,
    AlertTriangleIcon,
    XIcon,
  },
})
export default class ToastMessage extends Vue {
  @Prop() boldText!: string;
  @Prop() text!: string;
  @Prop() type!: "Success" | "Warning" | "Danger";
  @Prop() hidden!: boolean;

  @Watch("hidden")
  hideMeLater() {
    if (this.hidden) {
      return;
    }
    setTimeout(() => {
      this.$store.commit("hideToastMessage");
    }, 2400);
  }

  onCloseClick() {
    this.$store.commit("hideToastMessage");
  }
}
