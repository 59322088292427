
































import { Component, Vue } from "vue-property-decorator";
import TopNav from "../components/layout/Top-Nav.vue";
import TopBar from "../components/layout/Top-Bar.vue";
import MobileMenu from "../components/layout/Mobile-Menu.vue";
import ToastMessage from "../components/ui/ToastMessage.vue";

@Component({
  components: { TopNav, TopBar, MobileMenu, ToastMessage },
})
export default class MainContent extends Vue {
  mounted() {
    this.fetchUserProfile();
  }

  async fetchUserProfile() {
    try {
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://${this.$store.state.ROOT_URL}/profile`,
        {
          method: "POST",
          credentials: "include",
        }
      );
      if (response.status == 200) {
        const data = await response.json();
        this.$store.commit("setProfileData", {
          name: `${data.first_name} ${data.last_name}`,
          username: data.username,
          pic: data.profile_picture,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }
}
