

























import { Component, Vue } from "vue-property-decorator";
import AuthError from "../components/ui/AuthError.vue";

@Component({
  components: {
    AuthError,
  },
})
export default class SplashScreen extends Vue {
  message = "Loading content 🍾..";
  isAuthError = false;
  errorType = "Auth";

  mounted() {
    setTimeout(() => {
      this.initCheckPerms();
    }, 400);
  }

  async initCheckPerms() {
    this.message = "Checking authentication 🚀";

    try {
      const checkPermsResponse = await fetch(
        `${this.$store.state.PROTOCOL}://${this.$store.state.ROOT_URL}/check-perms`,
        {
          method: "POST",
          headers: {
            Origin: `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}`,
          },
          credentials: "include",
        }
      );

      if (checkPermsResponse.status == 200) {
        this.message = "Authenticated successfully ✔";
        const data = await checkPermsResponse.json();
        this.authenticateUser(data.token);
      } else {
        const err = await checkPermsResponse.json();
        //
         if (err[0].type == "user.not_authenticated") {
           window.location.href = `${this.$store.state.PROTOCOL}://${this.$store.state.ROOT_URL}/organization?redirect-to=${this.$store.state.PROTOCOL}://assettracker.${this.$store.state.ROOT_URL}`;
         } else {
           this.errorType = "Auth";
           this.isAuthError = true;
         }
        this.errorType = "Auth";
        this.isAuthError = true;
        //
      }
    } catch (e) {
      this.errorType = "Exception";
      this.isAuthError = true;
    }
  }

  async authenticateUser(token: string) {
    this.message = "Almost Done, Just a bit ✌";

    try {
      const authenticateResponse = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/authenticate`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token: token }),
        }
      );

      if (authenticateResponse.status == 200) {
        this.message = "Ready to fly? Let's Go ✈";

        const roles = await authenticateResponse.json();
        if (roles.includes("manager")) {
          this.$store.commit("setIsUserIsManager");
          // console.log(this.$store.state.isUserIsManager)
        }

        this.$store.commit("setUserAuth");
        this.$router.push({ path: "/asset-tracker" });
      } else {
        this.errorType = "Auth";
        this.isAuthError = true;
      }
    } catch (e) {
      this.errorType = "Exception";
      this.isAuthError = true;
    }
  }
}
